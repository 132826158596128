import './App.css';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import myFile from "./insomnia.yaml"

function App() {
  return (
    <div className="App">
      <SwaggerUI url={myFile}/>
    </div>
  );
}

export default App;
